<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>
<script>
	export default {}
</script>

<style>
	* {
		padding: 0;
		margin: 0;
		-webkit-tap-highlight-color: transparent;
	}

	/* 弹性盒子 */
	.flex {
		display: -webkit-box;
		display: -moz-box;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.inline_flex {
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: -webkit-inline-flex;
		display: inline-flex;
	}

	.flex_row {
		-webkit-flex-direction: row;
		-webkit-box-orient: horizontal;
		-moz-box-orient: horizontal;
		-moz-felx-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.flex_content {
		-webkit-justify-content: center;
		-webkit-box-pack: center;
		-moz-justify-content: center;
		justify-content: center;
	}

	.flex_align {
		-webkit-align-content: center;
		-ms-flex-line-pack: center;
		align-content: center;
	}

	.flex_column {
		-webkit-flex-direction: column;
		-webkit-box-orient: vertical;
		-moz-flex-direction: column;
		-moz-box-orient: vertical;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.flex_jc_start {
		-webkit-justify-content: flex-start;
		-moz-justify-content: flex-start;
		justify-content: flex-start;
	}

	.flex_jc_end {
		-webkit-justify-content: flex-end;
		-moz-justify-content: flex-end;
		justify-content: flex-end;
	}

	.flex_jc_sb {
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		justify-content: space-between;
	}

	.flex_jc_sa {
		-webkit-box-pack: justify;
		-webkit-justify-content: space-around;
		justify-content: space-around;
	}

	.flex_wrap {
		-webkit-flex-wrap: wrap;
		-moz-felx-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.aline_center {
		-webkit-align-items: center;
		-webkit-box-align: center;
		-moz-align-items: center;
		align-items: center;
	}

	.flex_1 {
		-prefix-box-flex: 1;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-moz-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	a:hover,
	a:link,
	a:visited,
	a:active,
	a:focus {
		text-decoration: none;
	}

	input,
	textarea {
		border: none;
		outline: none;
		background-color: transparent;
	}

	input {
		-webkit-appearance: none;
		-webkit-appearance: none;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}

	textarea {
		resize: none;
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none !important;
	}

	input[type='number'] {
		-moz-appearance: textfield;
	}
</style>